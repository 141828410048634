<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-01 12:13:37
 * @ Description: Fixer's public company profile page.
 -->

<template>
    <div>
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                style="position: relative;"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : $vuetify.breakpoint.md ? 'max-width: 960px;' : $store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            >
                <v-row>
                    <!-- Heading -->
                    <v-col
                        cols="12"
                    >
                        <v-btn
                            icon
                            @click="$router.push(fromPath)"
                            class="mb-1"
                        >
                            <v-icon
                                size="24"
                                color="#2E567A"
                                style="opacity: 0.65;"
                            >
                                {{ mdiArrowLeftThinCircleOutline }}
                            </v-icon>
                        </v-btn>
                        <span
                            class="os-22-sb"
                        >
                            {{ selectedNavItem.heading }}
                        </span>
                    </v-col>
                    <!-- Heading -->

                    <!-- Desktop Navigation -->
                    <v-col
                        cols="12"
                        class="os-13-r"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <ul
                            style="padding-left: 0px;"
                        >
                            <li
                                v-for="(item, index) in topNavItems"
                                :key="index"
                                :style="item.selected ? '' : 'color: #8F9BB3;'"
                            >
                                <div
                                    v-if="!item.hidden"
                                >
                                    <span
                                        :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                        style="cursor: pointer; position: relative;"
                                        @click="changeTopNavSelection(index, true)"
                                    >
                                        {{ item.text }}
                                    </span>
                                    <div
                                        style="background-color: #FFA858;  height: 2px; width: 35px; position: absolute;"
                                        :style="index > 0 ? $vuetify.breakpoint.xl ? 'margin-left: 4rem;' : 'margin-left: 2.5rem;' : ''"
                                        v-if="item.selected"
                                    />
                                </div>
                            </li>
                        </ul>
                    </v-col>
                    <!-- Desktop Navigation -->

                    <!-- Mobile Navigation -->
                    <v-col
                        v-else-if="$vuetify.breakpoint.smAndDown"
                        cols="12"
                        class="pa-0 os-13-r"
                    >
                        <v-sheet
                            class="mx-auto"
                            max-width="100%"
                            style="background-color: #F9FBFD;"
                        >
                            <v-slide-group
                                multiple
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(item, index) in topNavItems"
                                    :key="index"
                                    :slot-scope="index"
                                >
                                    <v-chip
                                        class="ma-2"
                                        outlined
                                        :color="item.selected ? '#FFA858' : '#8F9BB3'"
                                        :style="item.selected ? 'color: black;' : ''"
                                        style="font-size: 15px !important;"
                                        @click="changeTopNavSelection(index, true)"
                                        v-if="!item.hidden"
                                    >
                                        {{ item.text }}
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>
                    <!-- Mobile Navigation -->
                    
                    <v-col
                        cols="12"
                        style="position: relative;"
                    >
                        <!-- View Type Toggle Buttons -->
                        <v-btn
                            style="position: absolute; right: 66px; top: -41px; min-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="list"
                            :color="selectedView === 'list' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-l-lg"
                            @click="changeSelectedView('list')"
                            v-if="$vuetify.breakpoint.mdAndUp && (this.$route.path.includes('/services') || this.$route.path.includes('/industries'))"
                        >
                            <v-icon
                                :color="selectedView === 'list' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiFormatListBulleted }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            style="position: absolute; right: 16px; top: -41px; in-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="grid"
                            :color="selectedView === 'card' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-r-lg"
                            @click="changeSelectedView('card')"
                            v-if="$vuetify.breakpoint.mdAndUp && (this.$route.path.includes('/services') || this.$route.path.includes('/industries'))"
                        >
                            <v-icon
                                :color="selectedView === 'card' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiViewGridOutline }}
                            </v-icon>
                        </v-btn>
                        <!-- View Type Toggle Buttons -->

                        <!-- Router -->
                        <router-view
                            :selectedView="selectedView"
                            :companyUserID="companyUserID"
                            :avatar="avatar"
                        />
                        <!-- Router -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mdiFormatListBulleted, mdiViewGridOutline, mdiArrowLeftThinCircleOutline } from '@mdi/js'
    import { CompanyProfileDetailController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfilePublicCompanyPage',

        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromPath = from.fullPath
            })
        },

        watch: {
            '$route.path': {
                handler () {
                    this.getTopNavSelection()
                }
            }
        },

        data () {
            return {
                mdiFormatListBulleted,
                mdiViewGridOutline,
                mdiArrowLeftThinCircleOutline,
                selectedView: 'card',
                topNavItems: [
                    {
                        text: 'Company',
                        selected: true,
                        heading: 'Company Profile'
                    },
                    {
                        text: 'Services',
                        selected: false,
                        heading: 'Services',
                        hidden: true
                    },
                    {
                        text: 'Industries',
                        selected: false,
                        heading: 'Industries'
                    },
                    {
                        text: 'Reviews',
                        selected: false,
                        heading: 'Reviews'
                    }
                ],
                selectedNavItem: {
                    text: 'Company',
                    selected: true,
                    heading: 'Company Profile'
                },
                companyUserID: null,
                companyProfileDetailController: null,
                avatar: null,
                fromPath: null
            }
        },

        beforeMount () {
            this.companyProfileDetailController = new CompanyProfileDetailController()

            this.getTopNavSelection()

            if (!this.$route.query.view) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            } else this.selectedView = this.$route.query.view
        },

        async mounted () {
            await this.getCompanyProfileDetail()
        },

        methods: {
            getTopNavSelection () {
                let index

                switch (this.$route.path) {
                case `/profile/company/${this.$route.params.id}`:
                    index = 0
                    break;
                case `/profile/company/${this.$route.params.id}/services`:
                    index = 1
                    break;
                case `/profile/company/${this.$route.params.id}/industries`:
                    index = 2
                    break;
                case `/profile/company/${this.$route.params.id}/reviews`:
                    index = 3
                    break;
                }

                this.changeTopNavSelection(index)
            },

            changeTopNavSelection (newSelectedItemIndex, changeRoute) {
                const currentlySelectedItemIndex = this.topNavItems.findIndex(itemToFind => itemToFind.selected)
                if (currentlySelectedItemIndex > -1) this.topNavItems[currentlySelectedItemIndex].selected = false

                this.topNavItems[newSelectedItemIndex].selected = true
                this.selectedNavItem = this.topNavItems[newSelectedItemIndex]

                if (changeRoute) {
                    switch (this.selectedNavItem.text) {
                    case 'Company':
                        if (this.$route.path !== `/profile/company/${this.$route.params.id}`) this.$router.push({ path: `/profile/company/${this.$route.params.id}`, query: { view: this.selectedView } })
                        break;
                    case 'Services':
                        if (this.$route.path !== `/profile/company/${this.$route.params.id}/services`) this.$router.push({ path: `/profile/company/${this.$route.params.id}/services`, query: { view: this.selectedView } })
                        break;
                    case 'Industries':
                        if (this.$route.path !== `/profile/company/${this.$route.params.id}/industries`) this.$router.push({ path: `/profile/company/${this.$route.params.id}/industries`, query: { view: this.selectedView } })
                        break;
                    case 'Reviews':
                        if (this.$route.path !== `/profile/company/${this.$route.params.id}/reviews`) this.$router.push({ path: `/profile/company/${this.$route.params.id}/reviews`, query: { view: this.selectedView } })
                        break;
                    }
                }
            },

            changeSelectedView (viewType) {
                this.selectedView = viewType

                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            },

            async getCompanyProfileDetail () {
                try {
                    const company = await this.companyProfileDetailController.retrieve(this.$route.params.id)
                    this.companyUserID = company.user_id
                    this.avatar = company.avatar
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style>
    @import url('../../../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }
</style>
