import { render, staticRenderFns } from "./ProfilePublicCompanyPage.vue?vue&type=template&id=cb84866c&scoped=true&"
import script from "./ProfilePublicCompanyPage.vue?vue&type=script&lang=js&"
export * from "./ProfilePublicCompanyPage.vue?vue&type=script&lang=js&"
import style0 from "./ProfilePublicCompanyPage.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ProfilePublicCompanyPage.vue?vue&type=style&index=1&id=cb84866c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb84866c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VBtn,VChip,VCol,VIcon,VRow,VSheet,VSlideGroup,VSlideItem})
